import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <SEO title="Teilnahmebedingungen"/>
    <div className="top-area-bg">
      <div className="container">
        <div className="top-area-text-container">
          <h2 className="top-area-text-title cm-hidden-mobile cm-hidden-tablet">Teilnahmebedingungen</h2>
        </div>
      </div>
    </div>
    <div className="content-area">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 m-top-30">
            <h2 className="tnb-header">
              Teilnahmebedingungen der Aktion <h2 className="tnb-header hashtag-text">
              „#SupportYourLocalSportfachhandel</h2> – lokal kaufen, Umwelt schützen“ (nachfolgend: Aktion)
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              I. Veranstalter und Inhalt der Aktion:
            </h2>
            <ol type="1">
              <br/>
              <li>Veranstalter der Aktion ist die Schöffel Sportbekleidung GmbH, Ludwig-Schöffel-Straße 15, 86830 Schwabmünchen (nachfolgend: Schöffel oder Veranstalter)</li>
              <br/>
              <li>Aktion: Schöffel spendet einen Baum für den Kauf eines Schöffel-Produktes, das ausschließlich im <b>stationären</b> Handel in <b>Deutschland, Österreich oder der Schweiz</b>, im Zeitraum vom 11.06.2021 bis 18.07.2021 gekauft wurde. Der Kauf im Onlinehandel berechtigt also <b>nicht</b> zur Teilnahme!</li>
              <br/>
              <li>Diese Teilnahmebedingungen sind Voraussetzung für die Teilnahme. Mit der Teilnahme erklären Sie die Zustimmung zu den Teilnahmebedingungen.</li>
              <br/>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              II. Teilnahmevoraussetzungen und Durchführung
            </h2>
            <ol type="1">
              <br/>
              <li>Teilnahmeberechtigt an der Aktion sind natürliche Personen mit Wohnsitz in Deutschland, die das 18. Lebensjahr vollendet haben.
              Nicht teilnahmeberechtigt an der Aktion sind die Mitarbeiter von Schöffel und mit ihr verbundene Unternehmen. Schöffel behält sich vor, Personen von der Teilnahme auszuschließen, wenn berechtigte Gründe vorliegen, wie u.a.:</li>
              <ol type="a">
                <li>bei Manipulationen im Zusammenhang mit der Durchführung der Aktion,</li>
                <li>bei Verstößen gegen diese Teilnahmebedingungen,</li>
                <li>bei falschen oder irreführenden Angaben im Zusammenhang mit der Teilnahme an der Aktion.</li>
              </ol>
              <br/>
              <li>Die Teilnahme erfolgt, in dem der Kaufbeleg des Kaufs (Rechnung des Geschäfts) der den Voraussetzungen wie oben unter I.2) entspricht, bis zum 18.07.2021 auf die Website baum.schoeffel.com hochgeladen wird und die dort aufgezeigte technische Vorgehensweise bestätigt wird, wie auch die Zustimmung zu diesen Teilnahmebedingungen.</li>
              <br/>
              <li>Es ist nicht die Erreichung eines Mindestwerts des Kaufbelegs erforderlich.</li>
              <br/>
              <li>Die Teilnahme ist durch jede Person nur einmalig möglich.</li>
              <br/>
              <li>Der Teilnehmer kann seine Teilnahme bis zum Abschluss der Aktion jederzeit widerrufen, indem er den Veranstalter unter mail@schoeffel.com darüber informiert.</li>
              <br/>
              <li>Die teilnehmende Person wird unter der von ihr angegebenen und bestätigten Emailadresse von Schöffel innerhalb von 2 Wochen per E-Mail über die Durchführung der Aktion benachrichtigt. Wir werden einen Nachweis über den Ort der von Schöffel veranlassten Pflanzung des Baumes zusenden.
                Eine Aushändigung des Baumes an Teilnehmer/-in erfolgt naturgemäß nicht.</li>
            </ol>
            <br/><br/>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              III. Datenschutz
            </h2>
            <p>
              Für die Teilnahme an der Aktion ist die Angabe von persönlichen (personenbezogenen) Daten notwendig. Teilnehmer/-in versichert, dass die gemachten Angaben zur Person, insbesondere Vor-, Nachname und E-Mailadresse wahrheitsgemäß und richtig sind. Die Erfassung und Verarbeitung der personenbezogenen Daten dient ausschließlich zur Durchführung der Aktion und Zusendung der Benachrichtigung zur Durchführung. Sämtliche Daten werden nur für den Zweck und für die Dauer der Aktion gespeichert und maximal 3 Monate nach Ende der Aktion gelöscht, sofern keine gesetzlichen Aufbewahrungspflichten bestehen. Die Verarbeitung erfolgt auf Grund der Einwilligung des / der Betroffenen. (Art 6 (1) Buchst. a) DSGVO). Die Verarbeitung ist zur Wahrung von berechtigten Interessen des Verantwortlichen notwendig und nicht unverhältnismäßig. (Art 6 (1) Buchst. f) DSGVO).<br/>
              Die personenbezogenen Daten der Teilnehmer werden ohne Einverständnis weder an Dritte weitergegeben noch diesen zur Nutzung überlassen. Teilnehmer/in kann zu jeder Zeit Auskunft über die beim Veranstalter gespeicherten Daten erhalten, der Nutzung der Daten widersprechen und deren Löschung verlangen. Hierzu genügt ein einfaches Schreiben an Schöffel Sportbekleidung GmbH, Ludwig-Schöffel-Str. 15, 86830 Schwabmünchen. Nach Widerruf der Einwilligung werden die erhobenen und gespeicherten personenbezogenen Daten umgehend gelöscht.<br/>
              Weitere Regelungen zum Umgang mit personenbezogenen Daten können der Datenschutzerklärung des Veranstalters unter <a href="https://www.schoeffel.com/de/de/datenschutz" className="link-text">https://www.schoeffel.com/de/de/datenschutz</a> entnommen werden.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              IV. Facebook Disclaimer
            </h2>
            <p>
              Die Aktion steht in keiner Verbindung zu Facebook und wird in keiner Weise von Facebook gesponsert, unterstützt oder organisiert. Ansprechpartner und Verantwortlicher ist allein der Veranstalter.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              V. Haftung
            </h2>
            <p>Der Veranstalter haftet nicht für Schäden, die durch Fehler, Verzögerungen oder Unterbrechungen in der Übermittlung, bei Störungen der technischen Anlagen und des Services, unrichtige Inhalte, Verlust oder Löschung von Daten, Viren oder in sonstiger Weise bei der Teilnahme an der Aktion entstanden sind, es sei denn, dass solche Schäden vom Veranstalter (seinen Organen, Mitarbeitern oder Erfüllungsgehilfen) vorsätzlich oder grob fahrlässig herbeigeführt worden sind. Der Veranstalter ist stets bemüht, in technisch einwandfreier Qualität zu veröffentlichen und korrekte Aussagen zu machen. Der Veranstalter haftet jedoch nicht für Fehlaussagen oder technisch bedingter Fehler im Zusammenhang mit der Entscheidung über die Teilnahme an der Aktion. Vorstehende Haftungsbeschränkung gilt nicht bei der Verletzung von Leben, Körper und Gesundheit sowie bei arglistig verschwiegenen Mängeln.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              VI. Anwendbares Recht
            </h2>
            <p>
              Die Aktion unterliegt ausschließlich dem Recht der Bundesrepublik Deutschland.
              <br/><br/>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>
              VII.Salvatorische Klausel
            </h2>
            <p>
              Sollte eine Bestimmung dieser Teilnahmebedingungen ganz oder teilweise unwirksam sein oder werden, so wird dadurch die Gültigkeit dieser Teilnahmebedingungen im Übrigen nicht berührt. Statt der unwirksamen Bestimmung gilt diejenige gesetzlich zulässige Regelung, die dem in der unwirksamen Bestimmung zum Ausdruck gekommenen Sinn und Zweck wirtschaftlich am nächsten kommt. Entsprechendes gilt für den Fall des Vorliegens einer Regelungslücke in diesen Teilnahmebedingungen.
              <br/><br/>
            </p>
          </div>
        </div>
        <Link to="/" className="btn btn-primary">Zurück zur Aktion</Link>
      </div>
    </div>
  </Layout>
)

export default IndexPage
